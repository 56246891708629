import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import client from '../SanityClient'
import imageUrlBuilder from '@sanity/image-url'
import "../global.css";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        fontSize: "30px", // Customize size
        color: "black", // Black color
        right: "25px", // Position the arrow
        zIndex: 1,
        cursor: "pointer"
      }}
      onClick={onClick}
    >
      &#62;
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        fontSize: "30px", // Customize size
        color: "black", // Black color
        left: "25px", // Position the arrow
        zIndex: 1,
        cursor: "pointer"
      }}
      onClick={onClick}
    >
      &#60;
    </div>
  );
}

function SimpleSlider({ galleryData }) {
  const settings = {
    dots: false, // Disable dots
    arrows: true, // Enable arrows
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />, // Custom Next Arrow
    prevArrow: <PrevArrow />, // Custom Prev Arrow
  };

  const builder = imageUrlBuilder(client);

  function urlFor(source) {
    return builder.image(source);
  }

  // Add inline media query styles to hide slider on mobile devices
  const sliderStyle = {
    display: 'block', // Default display
    '@media (max-width: 768px)': {
      display: 'none' // Hide slider on mobile screens
    }
  };

  return (
    <div className="slider-container bg-white" style={sliderStyle}>
      <Slider {...settings}>
        {galleryData && galleryData.map((e, index) => {
          return (
            <Link key={index} to={{ pathname: '/gallery' }} state={e}>
              <img
                className="w-full h-[700px] md:h-auto overflow-hidden shrink-0 object-contain sm:w-[100%] sm:h-auto"
                alt=""
                src={urlFor(e.coverImg).url()}
              />
            </Link>
          );
        })}
      </Slider>
    </div>
  )
}

export default SimpleSlider;
